<template>
    <div>
        <v-card v-if="this.loadingDatas == false">
            <v-card-title>
                <v-btn text small @click="backToPrevious" class="small">
                    <v-icon>{{ icons.mdiKeyboardBackspace }} </v-icon> &nbsp;Back to main page
                </v-btn>
                <!-- <v-btn icon text class="mr-3" desable v-if="services.status === '0'">
                    <v-icon>{{ icons.mdiTrashCan }}</v-icon>
                </v-btn> -->
                <v-btn icon text class="mr-3" @click="editService" v-if="services.status === '0'">
                    <v-icon>{{ icons.mdiFileEditOutline }}</v-icon>
                </v-btn>
                <!-- <v-btn icon text class="mr-3" @click="dialogAddImage = true" v-if="services.status === '0'">
                    <v-icon>{{ icons.mdiCamera }}</v-icon>
                </v-btn> -->
                <v-btn icon text @click="publishService" v-if="services.status === '0'">
                    <v-icon>{{ icons.mdiShareVariantOutline }}</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-card-subtitle class="text-md pa-0" v-if="services.status === '0'">
                    <v-icon size="13" class="error--text mr-1">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
                    <span class="error--text text-caption">Draft</span>
                </v-card-subtitle>
                <v-card-subtitle class="text-md pa-0" v-if="services.status === '1'">
                    <v-icon size="13" class="primary--text mr-1">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
                    <span class="primary--text text-caption">published</span>
                </v-card-subtitle>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" class="d-flex flex-row align-centr">
                            <div class="mx-4 d-bloc">
                                <h4>
                                    <span class="secondary--text">{{
                                        this.$store.getters.getCompany.name
                                    }}</span>
                                </h4>
                                <span>{{ services.name }}</span>
                            </div>
                            <v-spacer></v-spacer>
                            <span>{{ services.domain }}</span>
                        </v-col>

                        <v-col cols="12" class="pt-0">
                            <v-divider class="mb-6"></v-divider>
                            <v-card elevation="0" tile>
                                <v-card-title class="subaccent">{{ services.title }} </v-card-title>

                                <v-card-text v-html="services.description">
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card-subtitle class="subaccent">
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="12" class="d-flex flex-row">
                            <v-spacer> </v-spacer>
                            <v-btn x-small text outlined color="primary" class="primaryAccent mr-2"
                                @click="publishService" v-if="services.status === '0'">
                                <v-icon small>{{ icons.mdiShareVariantOutline }}</v-icon>
                                <span class="ml-2 text-caption">Publish {{ services.name }}</span>
                            </v-btn>
                            <v-dialog v-model="dialogAddImage" width="500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn x-small text outlined color="secondary" class="subsecondary" v-bind="attrs"
                                        v-on="on">
                                        <v-icon small>{{ icons.mdiCamera }}</v-icon> <span class="ml-2">Add
                                            images</span>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        <upload></upload>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="closeImageDialog">
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>

                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-text>
                <v-container>
                    <v-row class="service-img-container mx-1 rounded-lg">
                        <!-- <v-img :src="url_img + '/test/img1.jpg'" class="grey lighten-2"></v-img> -->
                        <v-col v-for="(image, n) in servicesImages" :key="n" class="d-flex child-flex relative" cols="4">
                            <v-btn icon small color="error" class="border suberror delete-image" @click="deleteImage(image)">
                                <v-icon size="24" class="ma-2" color="error">{{
                                    icons.mdiCloseCircle
                                    }}</v-icon>
                            </v-btn>
                            <v-img :src="url_img + image.path + image.name"
                                :lazy-src="url_img + image.path + decodeImg(image.name)" aspect-ratio="1"
                                class="secondary lighten-2">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="secondary lighten-5">
                                        </v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <v-card v-else>
            <v-container>
                <v-col cols="12">
                    <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                        <v-skeleton-loader v-bind="attrs" type="card-avatar, article, actions"></v-skeleton-loader>
                    </v-sheet>
                </v-col>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import {
    mdiKeyboardBackspace, mdiCamera, mdiTrashCan,
    mdiCalendarRange, mdiMapMarkerOutline, mdiFileEditOutline,
    mdiCheckboxMarkedCircle, mdiCloseCircle, mdiShareVariantOutline
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import Upload from './Upload.vue'


export default {
    inject: {
        theme: {
            default: { isDark: false },
        },
    },
    data() {
        return {
            dialogAddImage: false,
            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
            loadingDatas: true,
            loadingImage: true,
            mydataview: [],
            services: {},
            servicesImages: []
        }
    },
    components: {
        Upload,
    },
    beforeMount() {
        this.loadingDatas = true
        const s_id = this.$route.params.f_id
        // Drequest.api(`lazyloading.service?dfilters=on&id:eq=${this.$store.getters.getService.id}`)
        Drequest.api(`lazyloading.service?dfilters=on&id:eq=${s_id}`)
            .get((response) => {
                if (response.success === true) {
                    this.services = {
                        "id": response.listEntity[0].id,
                        "name": response.listEntity[0].name,
                        "title": response.listEntity[0].title,
                        "description": response.listEntity[0].description,
                        "domain": response.listEntity[0].domain,
                        "status": response.listEntity[0].status,
                        "enterprise": response.listEntity[0].enterprise,
                    }
                    this.loadingDatas = false
                    this.LoadImage()
                } else {
                    this.$fire({
                        type: "error",
                        text: "Error occured, refresh page..!",
                        timer: 3000
                    })
                    this.loadingDatas = false
                }
            })
            .catch((err) => {
                console.log("err")

                this.loadingDatas = false
            })
    },
    methods: {
        deleteImage(value){
            this.servicesImages = this.servicesImages.filter(item => item.key !== value.key)
        },
        decodeImg(value){
            return decodeURIComponent(value)
        },
        editService() {
            this.$router.push({ name: 'company.service.edit', params: { value: this.services.id } })
        },
        backToPrevious() {
            this.$router.go(-1)
        },
        closeImageDialog() {
            this.LoadImage()
            this.dialogAddImage = false
        },
        publishService() {
            Drequest.api(`service.publish?id=${this.$store.getters.getService.id}`)
                .post((response) => {
                    if (response.success === true) {
                        this.services.status = '' + response.service.status
                        this.$fire({
                            type: "success",
                            text: `${this.services.name} succefull published`,
                            timer: 3000
                        })
                        this.loadingImage = false
                    } else {
                        this.$fire({
                            type: "error",
                            text: "Retry later..!",
                            timer: 3000
                        })
                        this.loadingImage = false
                    }
                })
                .catch((err) => {
                    console.log("err")
                    this.loadingImage = false
                })
        },
        LoadImage() {
            this.loadingImage = true
            Drequest.api(`lazyloading.serviceimage?dfilters=on&service_id:eq=${this.$store.getters.getService.id}`)
                .get((response) => {
                    if (response.success === true) {
                        // if (response.listEntity.length > 0) {
                        //     this.servicesImages = []
                        // }
                        response.listEntity.forEach((value, index) => {
                            this.servicesImages.push({
                                key: value.id,
                                name: value.name,
                                path: value.path + '/'
                            })
                        })
                        console.log(this.servicesImages)
                        this.loadingImage = false
                    } else {
                        this.$fire({
                            title: "Erreur inattendue!",
                            type: "error",
                            text: "Veuillez recharger la page..!",
                            timer: 3000
                        })
                        this.loadingImage = false
                    }
                })
                .catch((err) => {
                    console.log("err")
                    this.loadingImage = false
                })
        },
    },
    setup() {
        const icons = {
            mdiKeyboardBackspace, mdiTrashCan, mdiCalendarRange,
            mdiMapMarkerOutline, mdiMapMarkerOutline, mdiCheckboxMarkedCircle, mdiCloseCircle,
            mdiCamera, mdiShareVariantOutline, mdiFileEditOutline
        }
        const url_img = Drequest.__envimg
        return {
            icons, url_img
        }
    }
}
</script>

<style lang="scss" scoped>
.request-read-content {
    border: solid 1px;
    margin-top: 2rem;
}

.service-img-container {
    border: solid 1px var(--v-subaccent-base);
}
.delete-image{
    position: absolute;
    top: 0.4em;
    right: 0.4em;
    z-index: 400;
}
</style>